import Pickr from '@simonwep/pickr/dist/pickr.min';
document.addEventListener('turbolinks:load', function() {
  if (!document.querySelector('.edit_signature')) {
    return;
  }
  // set scrollspy manually to body because it wasn't working within a div tag
  $('body').scrollspy({ target: '#list-menu', offset: 56 })

  // overlay handler for showing template options
  $('.preview-visig').on("click", function(event) {
    $(".preview-options").removeClass("active");
    $(event.target).siblings(".preview-options").addClass("active");
  });

  // remove overlay when user clicks outside
  $(document).on("click", function(e) {
    if ($(e.target).is(".preview-visig") === false) {
      $(".preview-options").removeClass("active");
    }
  });

  $('#choose_horizontal_template').on("click", function(e) {
    $(".preview-visig").removeClass("selected");
    $(e.target).closest(".preview-options").siblings(".preview-visig").addClass("selected");
    const inputForm = document.getElementById('video_template_field');
    inputForm.value = "horizontal";
    e.preventDefault();
  });

  $('#choose_vertical_template').on("click", function(e) {
    $(".preview-visig").removeClass("selected");
    $(e.target).closest(".preview-options").siblings(".preview-visig").addClass("selected");
    const inputForm = document.getElementById('video_template_field');
    inputForm.value = "vertical";
    e.preventDefault();
  });

  // $('#publish_landing_page').on("click", function(e) {
  //   $(".preview-visig").removeClass("selected");
  //   $(e.target).closest(".preview-options").siblings(".preview-visig").addClass("selected");
  //   const inputForm = document.getElementById('video_template_field');
  //   inputForm.value = "vertical";
  //   e.preventDefault();
  // });

  window._wapiq = window._wapiq || [];
  _wapiq.push(function(W) {
    window.wistiaUploaderButtonOnly = new W.Uploader({
      accessToken: "db498a218422b2d361a68d2e533e80dc84a694385e8d34d65ea0c6c449fffc45",
      button: "wistia_upload_button_button_only",
      projectId: "t7h6jggjzt",
      preview: "video_container"
    });
    $("#wistia_upload_button_text").html("Update VSIG®");
    window.wistiaUploaderButtonOnly.bind('uploadsuccess', function(file, media) {
      const wistia_id = media.id;
      const inputForm = document.getElementById('video_id_field');
      console.log(inputForm);
      const old_id = inputForm.value;
      inputForm.value = wistia_id;
      $.ajax({
        type: "DELETE",
        url: "https://api.wistia.com/v1/medias/" + old_id + ".json?api_password=db498a218422b2d361a68d2e533e80dc84a694385e8d34d65ea0c6c449fffc45",
        data: "",
        success: function(msg) {
          alert("Data Deleted: " + msg);
        }
      });
      $("#video_signature_form").submit();
    });
  });

  const $backgroundInput = document.getElementById("video_background_color");
  var currentColor = $backgroundInput.value ? $backgroundInput.value : "#2c2c2c"

  const pickr = Pickr.create({
    el: '.color-picker',
    theme: 'classic', // or 'monolith', or 'nano'
    defaultRepresentation: 'HEX',
    lockOpacity: true,
    swatches: [],
    default: currentColor,
    components: {

      // Main components
      preview: true,
      opacity: true,
      hue: true,

      // Input / output Options
      interaction: {
        hex: true,
        rgba: true,
        hsla: true,
        hsva: true,
        cmyk: true,
        input: true,
        clear: true,
        save: true
      }
    }
  });

  pickr.on('save', (color, instance) => {
    $backgroundInput.value = color.toHEXA().toString();
  });
});