// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("trix/dist/trix")
require("@rails/actiontext")
require("bootstrap/dist/js/bootstrap")

import Chartkick from "chartkick";
window.Chartkick = Chartkick;

// for Chart.js
import Chart from "chart.js";
Chartkick.addAdapter(Chart);

//custom modules
require("video_signature")
require("stripe_subscription")

document.addEventListener('turbolinks:load', function() {
  if (!document.querySelector('.onboarding')) {
    return;
  }
  window._wapiq = window._wapiq || [];
  _wapiq.push(function(W) {
    window.wistiaUploader = new W.Uploader({
      accessToken: "db498a218422b2d361a68d2e533e80dc84a694385e8d34d65ea0c6c449fffc45",
      dropIn: "wistia_uploader",
      projectId: "t7h6jggjzt",
      theme: "light_background"
    });

    window.wistiaUploader.bind('uploadembeddable', function(file, media) {
      const wistia_id = media.id;
      const inputForm = document.getElementById('video_id_field');
      console.log(inputForm)
      inputForm.value = wistia_id;
      $("#video_signature_form").submit();
      // const event = new Event('input');
      //    inputForm.dispatchEvent(event);
    });
  });
});

document.addEventListener('turbolinks:load', function() {
  if (!document.querySelector('.yolo')) {
    return;
  }

  $.ajax({
    type: "GET",
    url: "https://api.wistia.com/v1/stats/events.json?media_id=505ht30m67&api_password=db498a218422b2d361a68d2e533e80dc84a694385e8d34d65ea0c6c449fffc45&start_date=2019–05–20&end_date=2019–06–03",
    data: "",
    success: function(msg) {
      var json = msg
      json.map(e => e.received_at = e.received_at.substring(0, 10))
      console.log(JSON.stringify(groupBy(json, "received_at"), null, 2));
    }
  });

  var groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
});


require("trix")